<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="cotizacionesItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end" v-if="canCreate">
                  <v-btn
                    color="primary"
                    @click="openModalEdit()"
                    class="to-right"
                  >
                    Nueva cotización
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEdit(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar cotización</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteCotizacion(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar cotización</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="isOpenModalEdit"
      v-model="isOpenModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditCotizacionMoneda
        :editCotizacionItem="editCotizacionItem"
        @closeAndReload="closeAndReload"
      ></EditCotizacionMoneda>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditCotizacionMoneda from "@/components/modules/administracion-sistema/monedas/EditCotizacionMoneda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
export default {
  name: "CotizacionesMonedas",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    Ayuda,
    EditCotizacionMoneda,
  },
  data: () => ({
    routeToGo: "MonedasGeneral",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    title: "Cotizaciones de monedas",
    titleDelete: "Desea eliminar la cotización?",
    search: "",
    optionCode: enums.webSiteOptions.COTIZACIONES_MONEDAS,
    idToDelete: null,
    editCotizacionItem: null,
    cotizacionesItems: [],
    headers: [
      {
        text: "Moneda base",
        value: "monedaBase.value",
        sortable: false,
      },
      {
        text: "Moneda referencia",
        value: "monedaRef.value",
        sortable: false,
      },
      {
        text: "Cotización",
        value: "cotizacion",
        sortable: false,
        align: "end",
      },
      {
        text: "Vigencia desde",
        value: "fecDesde",
        sortable: false,
        align: "center",
      },
      {
        text: "Vigencia hasta",
        value: "fecHasta",
        sortable: false,
        align: "center",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    showDeleteModal: false,
    isLoading: false,
    loadingBtnDelete: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    isOpenModalEdit: false,
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
    this.loadCotizacionesMonedas();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getCotizacionesMonedas: "AdministracionSistema/getCotizacionesMonedas",
      deleteCotizacionMoneda: "AdministracionSistema/deleteCotizacionMoneda",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_COTIZACION:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_COTIZACION:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_COTIZACION:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadCotizacionesMonedas() {
      this.isLoading = true;
      const response = await this.getCotizacionesMonedas();
      this.cotizacionesItems = response;
      this.isLoading = false;
    },
    deleteCotizacion(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.mcId;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.deleteCotizacionMoneda(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Cotización eliminada con exito",
          });
          this.loadingBtnDelete = false;
          this.loadCotizacionesMonedas();
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingBtnDelete = false;
      }
    },
    openModalEdit(item) {
      this.isOpenModalEdit = true;
      this.editCotizacionItem = item;
    },
    closeAndReload() {
      this.isOpenModalEdit = false;
      this.loadCotizacionesMonedas();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>